import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationController, Platform, ToastController } from '@ionic/angular';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Storage } from '@ionic/storage';
import { SwUpdate } from '@angular/service-worker';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { filter, map } from 'rxjs/operators';
const Parse = require('parse');

@Injectable({
  providedIn: 'root'
})
export class JalteaService   {
  public towns: any = ["Adjuntas", "Aguada", "Aguadilla", "Aguas Buenas", "Aibonito", "Arecibo", "Arroyo", "Añasco", "Barceloneta",
  "Barranquitas", "Bayamón", "Cabo Rojo", "Caguas", "Camuy", "Canóvanas", "Carolina", "Cataño", "Cayey", "Ceiba", "Ciales", "Cidra", "Coamo", "Comerío",
  "Corozal", "Culebra", "Dorado", "Fajardo", "Florida", "Guayama", "Guayanilla", "Guaynabo", "Gurabo", "Guánica", "Hatillo", "Hormigueros", "Humacao", "Isabela",
  "Jayuya", "Juana Díaz", "Juncos", "Lajas", "Lares", "Las Marías", "Las Piedras", "Loiza", "Luquillo", "Manatí", "Maricao", "Maunabo", "Mayagüez", "Moca", "Morovis",
  "Naguabo", "Naranjito", "Orocovis", "Patillas", "Peñuelas", "Ponce", "Quebradillas", "Rincón", "Rio Grande", "Sabana Grande", "Salinas", "San Germán", "San Juan",
  "San Lorenzo", "San Sebastián", "Santa Isabel", "Toa Alta", "Toa Baja", "Trujillo Alto", "Utuado", "Vega Alta", "Vega Baja", "Vieques", "Villalba", "Yabucoa", "Yauco"];
  toastEl: any;
  public newOrder: any;
  loading: any;
  loadingSpinner: any;

  constructor(
    public toastCtrl: ToastController,
    private router: Router,
    private storage: Storage,
    private updates: SwUpdate,
    private platform: Platform,
    private animationCtrl: AnimationController
  ) { 

    /*if (this.updates) {
      this.updates.checkForUpdate();
      this.updates.available.subscribe((event) => { 
          if (event.available) {
             window.location.reload();
          }; 
      });
  };*/
  this.updates.versionUpdates.subscribe(evt => {
    this.startLoading();
    switch (evt.type) {
      case 'VERSION_DETECTED':
        //console.log(`Downloading new app version: ${evt.version.hash}`); 
        this.stopLoading();
        break;
      case 'VERSION_READY':
        //console.log(`Current app version: ${evt.currentVersion.hash}`);
        //console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
        this.stopLoading();
        window.location.reload();
        break;
      case 'VERSION_INSTALLATION_FAILED':
        //console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
        this.stopLoading();
        break;
      case 'NO_NEW_VERSION_DETECTED':
        //console.log('Nothing new');
        this.stopLoading();
    }
  });

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
  apiKey: "AIzaSyAZDeUQP17-OcY5P6TsRKivTK4b6Fm9qEQ",
  authDomain: "jalteaapp.firebaseapp.com",
  projectId: "jalteaapp",
  storageBucket: "jalteaapp.appspot.com",
  messagingSenderId: "841515408985",
  appId: "1:841515408985:web:e76c9799957a539e8c9b48",
  measurementId: "G-6Q2NH6X165"
}; 
// Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  this.initStorage();

  this.platform.ready().then(() => {
    this.loadAudio();
    this.initAnims();
  });
  }
  
  async initStorage(){
    await this.storage.create();
  //  console.log("Database created")
  }

  async saveKeyData(key:string,value:any){
    await this.storage.set(key,value);
  //  console.log(`${key}: ${await this.storage.get(key)}`);
  }

  async getKeyData(key:string){
    const data = await this.storage.get(key);
    return data;
  }

  async getAllKeys(){
    const data = await this.storage.keys();
    return data;
  }

  async toast(message: string, duration: number){
    this.toastEl = await this.toastCtrl.create({
       message: message,
       duration: duration, 
       cssClass: 'toast'
    });
    await this.toastEl.present();
  }

  async toastMissingPlates(message:string){
    const toast = await this.toastCtrl.create({
      message: message, 
      cssClass: 'plateToast',
      buttons: ['OK'],
      duration: 5000
   });
   await toast.present();
  }

  loadAudio(){
    this.newOrder = new Audio();
    this.newOrder.src = 'assets/sounds/alert.wav';
  }

  async logout(){
    await Parse.User.logOut();
    await this.toast('You have been logged out succesfully', 1000);
    this.router.navigate(['/splash'], {replaceUrl: true})
  }

  async getUser(){
    let user = await Parse.User.current();
    if(user){
      const query = new Parse.Query('User');
      query.equalTo('objectId', user.id);
      const result = await query.first()
      .catch((err:any)=>{
        this.handleParseError(err);
      });
      return result;
    } 
  }

  async handleParseError(err:any){  
    this.toast(err.message, 2000);
    switch (err.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:  
        Parse.User.logOut();
        this.router.navigate(['/home'], {replaceUrl:true});  
        window.location.reload(); 
        break; 
    }
  }

  handleGoogleSigninError(error:any){
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    console.log(errorCode,errorMessage,email)
  }

  initAnims(){ 
    const loading = document.getElementById('loadingOverlay')!;
    this.loading = this.animationCtrl.create()
    .addElement(loading) 
    .duration(500)  
    .easing('ease-in-out') 
    .keyframes([
      {offset:0, transform:'translate(-50%, -50%) scale(0)', opacity: 0}, 
      {offset:0.2, transform:'translate(-50%, -50%) scale(1)', opacity: 0}, 
      {offset:1, transform:'translate(-50%, -50%) scale(1)', opacity: 1} 
    ])  

    const spinner = document.getElementById('loadingSpinner')!;
    this.loadingSpinner = this.animationCtrl.create()
    .addElement(spinner) 
    .duration(1500) 
    .iterations(100)
    .keyframes([
      {offset:0, transform:'rotate(360deg)'},
      {offset:1, transform:'rotate(0deg)'}
    ])
     
  }

  async startLoading(){
    this.loading.direction('normal');
    this.loading.play();
    this.loadingSpinner.play();
  }

  async stopLoading(){
    setTimeout(async()=>{
      this.loading.direction('reverse');
      await this.loading.play(); 
      this.loadingSpinner.stop();
    },750)
  }


}

 