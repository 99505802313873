import { Component, OnInit } from '@angular/core'; 
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { JalteaService } from '../jaltea.service';

const Parse = require('parse');

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.page.html',
  styleUrls: ['./create-account.page.scss'],
})
export class CreateAccountPage implements OnInit {
  fname: string;
  lname: string;
  phone: string;
  email: string;
  password: string; 
  subscribe: boolean = true;

  constructor(
    private service: JalteaService,
    private router: Router,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  checkInputs(){
     
    if(!this.fname){
      this.service.toast('Name is required',1000);
      return;
    } else
    if(!this.lname){
      this.service.toast('Last name is required', 1000);
      return;
    } else 
    if(!this.phone){
      this.service.toast('Please enter a phone number', 1000);
      return;
    } else 
    if(this.phone.length != 10){
      this.service.toast('Phone number invalid', 1000);
      return;
    } else 
    if(!this.email){
      this.service.toast('Email is required', 1000);
      return;
    } else 
    if(!this.password){
      this.service.toast('Please enter a password', 1000);
      return;
    } else 
    if(this.password.length < 4){
      this.service.toast('Password length needs to be at least 4 characters long', 1500);
      return;
    }  
    let emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validEmailDetected = emailRegExp.test(this.email);
    if (!validEmailDetected) {
        this.service.toast("Email is invalid", 1000);
        return;
    } else {
      this.createAccount();
    }
  }

  async createAccount(){
    const user = new Parse.User();
    user.set('username', this.email);
    user.set('email',this.email);
    user.set('phone',this.phone);
    user.set('password',this.password);
    user.set('firstName', this.fname);
    user.set('lastName', this.lname);
    user.set('acceptTerms', true); 
    user.set('subscribeToNews',this.subscribe);
    user.setACL( new Parse.ACL(user.id));
     
    await user.signUp().then(()=>{

      this.service.toast('Account creation succesfull', 1000);
      this.dismiss();
      //this.router.navigate(['/home'],{replaceUrl: true}); 
    }).catch((err: { message: any; })=>{

      this.service.handleParseError(err);
      console.log(err);

    })
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }

}
