import { NgModule, isDevMode} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';   
import { Storage } from '@ionic/storage'; 
import { CreateAccountPageModule } from './create-account/create-account.module';
import { ServiceWorkerModule } from '@angular/service-worker'; 
import { NgxColorsModule } from 'ngx-colors';  
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [  
    BrowserModule, 
    //SocialLoginModule,
    BrowserAnimationsModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    CreateAccountPageModule, 
    NgxColorsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

  ],
  providers: [  
    Storage, 
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy, 
    },
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
  
})
export class AppModule {}
