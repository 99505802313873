  
<ion-content scrollY="false"> 

  <div class="container">
     <img src="assets/background.png" />
  </div> 

 <!--div class="jalteaLogo" > 
    <img src="assets/jaltealogo.png" alt="JALTEA logo"/> 
 </div-->
 
  <ion-row style="justify-content: center; height:100%"> 
    <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="11" size-xs="11" class="formContainer">
      <ion-button fill="clear"  size="small" (click)="dismiss()">
        <ion-icon name="close-circle" color="dark" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-list lines="none">
        <ion-item lines="none">
          <ion-label position="stacked">First Name</ion-label>
          <ion-input type="text" autocapitalize="words" [(ngModel)]="fname"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-label  position="stacked">Last Name</ion-label>
          <ion-input type="text" autocapitalize="words" [(ngModel)]="lname"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Phone</ion-label>
          <ion-input type="tel" [(ngModel)]="phone" maxlength="10"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Email</ion-label>
          <ion-input type="email" [(ngModel)]="email"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Password</ion-label>
          <ion-input type="password" [(ngModel)]="password"></ion-input>
        </ion-item>
        <ion-item lines="none"> 
          <ion-text style="font-size: 10px;">By creating an acccount, you accept the <a>Terms and Conditions</a> and our<a>Privacy Policy</a></ion-text>
        </ion-item>
        <ion-item lines="none">
          <ion-checkbox slot="start" [(ngModel)]="subscribe"></ion-checkbox>
          <ion-text style="font-size: 10px;">I want to receive emails from JALTEA</ion-text>
        </ion-item>
      </ion-list>
      <ion-button mode="md" shape="round" expand="block" (click)="checkInputs()" >Submit</ion-button>
    </ion-col>
  </ion-row> 
</ion-content>
