import { Component } from '@angular/core';  
import { JalteaService } from './jaltea.service';

import Parse from 'parse';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'], 
})
export class AppComponent {
  businessUrl: string = ''; 
  constructor(
    private service: JalteaService
  ) {
    Parse.initialize(
    'd9wHREhvCfRHBkl5CqTf0B4kc47tBr7V9wnd2yvb',
    'Wmuy1ZrP8KJPGsWm9XMaWQfUeCPnCeX3A61ibqzZ'
    )
    Parse.serverURL = 'https://jaltea.b4a.io/';
  
  }
 
}
